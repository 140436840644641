import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = { class: "text-center" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "row row-cols-1 col-md-2 g-0 w-100 gap-md-5 my-3" }
const _hoisted_15 = {
  key: 0,
  class: "overflow-auto d-flex flex-wrap",
  style: {"max-height":"24rem"}
}
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("form", {
              class: "",
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", {
                    for: "level",
                    class: "form-label"
                  }, "Level", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.level
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.level) = $event)),
                        class: "form-control",
                        id: "level",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.level]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "cummulativeWithdrawerLimit",
                    class: "form-label"
                  }, "Cummulative Withdrawal Limit", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.cummulativeWithdrawerLimit
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.cummulativeWithdrawerLimit) = $event)),
                        class: "form-control",
                        id: "cummulativeWithdrawerLimit",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.cummulativeWithdrawerLimit]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", {
                    for: "dailyWithdrawerLimit",
                    class: "form-label"
                  }, "Daily Withdrawal Limit", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.dailyWithdrawerLimit
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.dailyWithdrawerLimit) = $event)),
                        class: "form-control",
                        id: "withdrawerLimit",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.dailyWithdrawerLimit]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _cache[11] || (_cache[11] = _createElementVNode("label", {
                    for: "description",
                    class: "form-label"
                  }, "Description", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.description
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("textarea", {
                        rows: "3",
                        type: "number",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.description) = $event)),
                        placeholder: "Add description",
                        class: "form-control",
                        id: "description",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.description]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[12] || (_cache[12] = _createElementVNode("label", {
                  for: "requirements",
                  class: "form-label"
                }, "Requirements", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.requirements
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("textarea", {
                        type: "text",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newRequirement) = $event)),
                        placeholder: "Add requirement",
                        class: "form-control",
                        id: "requirements",
                        onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.addRequirement && _ctx.addRequirement(...args)), ["prevent"]), ["enter"]))
                      }, null, 544), [
                        [_vModelText, _ctx.newRequirement]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("button", {
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addRequirement && _ctx.addRequirement(...args))),
                      disabled: _ctx.newRequirement == '',
                      type: "button",
                      class: "btn btn-info"
                    }, "Add", 8, _hoisted_11)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("button", {
                  disabled: _ctx.formData.requirements.length == 0,
                  type: "submit",
                  class: "btn btn-dark"
                }, "Create KYC setting", 8, _hoisted_13)
              ])
            ], 32),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.formData.requirements)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.requirements, (req, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: req,
                        class: "d-inline-flex align-items-center bg-light rounded-md p-3 py-1 m-3 ml-0"
                      }, [
                        _createTextVNode(_toDisplayString(req) + " ", 1),
                        _createElementVNode("button", {
                          onClick: ($event: any) => (_ctx.removeRequirement(index)),
                          class: "btn btn-link text-danger"
                        }, _cache[13] || (_cache[13] = [
                          _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                        ]), 8, _hoisted_16)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}