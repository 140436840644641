
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateKycForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const newRequirement = ref("");

    interface FormData {
      level: string;
      cummulativeWithdrawerLimit: number | null;
      dailyWithdrawerLimit: number | null;
      description: string | null;
      requirements: string[];
    }
    const formData = ref<FormData>({
      level: "",
      cummulativeWithdrawerLimit: null,
      dailyWithdrawerLimit: null,
      description: null,
      requirements: [],
    });
    const formError = ref({});
    const loading = ref(false);

    const addRequirement = (): void => {
      if (
        newRequirement.value &&
        !formData.value.requirements.includes(newRequirement.value)
      ) {
        formData.value.requirements.push(newRequirement.value);
        newRequirement.value = "";
      }
    };
    const removeRequirement = (index: number) => {
      formData.value.requirements.splice(index, 1);
    };

    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/admin/kyc`, formData.value);
        ShowSuccess("Kyc settings created Successfully", () => {
          formData.value = {
            level: "",
            cummulativeWithdrawerLimit: null,
            dailyWithdrawerLimit: null,
            description: null,
            requirements: [],
          };
        });
      } catch (e:any) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create kyc: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      addRequirement,
      removeRequirement,
      newRequirement,
      formData,
      formError,
      loading,
    };
  },
});
